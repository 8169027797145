import {
    createContext,
    useContext,
    forwardRef,
    useCallback,
    useEffect,
    useState,
} from "react";
import useEmblaCarousel, {
    type UseEmblaCarouselType,
} from "embla-carousel-react";

import { cx } from "class-variance-authority";
import { ChevronButton } from "components/Button/ChevronButton";

type CarouselApi = UseEmblaCarouselType[1];
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>;
type CarouselOptions = UseCarouselParameters[0];
type CarouselPlugin = UseCarouselParameters[1];

type CarouselProps = {
    opts?: CarouselOptions;
    plugins?: CarouselPlugin;
    orientation?: "horizontal" | "vertical";
    setApi?: (api: CarouselApi) => void;
};

type CarouselContextProps = {
    carouselRef: ReturnType<typeof useEmblaCarousel>[0];
    api: ReturnType<typeof useEmblaCarousel>[1];
    scrollPrev: () => void;
    scrollNext: () => void;
    canScrollPrev: boolean;
    canScrollNext: boolean;
} & CarouselProps;

const CarouselContext = createContext<CarouselContextProps | null>(null);

function useCarousel(): CarouselContextProps {
    const context = useContext(CarouselContext);

    if (!context) {
        throw new Error("useCarousel must be used within a <Carousel />");
    }

    return context;
}

const Carousel = forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement> & CarouselProps
>(
    (
        {
            orientation = "horizontal",
            opts,
            setApi,
            plugins,
            className,
            children,
            ...props
        },
        ref,
    ) => {
        const [carouselRef, api] = useEmblaCarousel(
            {
                ...opts,
                axis: orientation === "horizontal" ? "x" : "y",
            },
            plugins,
        );
        const [canScrollPrev, setCanScrollPrev] = useState(false);
        const [canScrollNext, setCanScrollNext] = useState(false);

        const onSelect = useCallback((api: CarouselApi) => {
            if (!api) {
                return;
            }

            setCanScrollPrev(api.canScrollPrev());
            setCanScrollNext(api.canScrollNext());
        }, []);

        const scrollPrev = useCallback(() => {
            api?.scrollPrev();
        }, [api]);

        const scrollNext = useCallback(() => {
            api?.scrollNext();
        }, [api]);

        const handleKeyDown = useCallback(
            (event: React.KeyboardEvent<HTMLDivElement>) => {
                if (event.key === "ArrowLeft") {
                    event.preventDefault();
                    scrollPrev();
                } else if (event.key === "ArrowRight") {
                    event.preventDefault();
                    scrollNext();
                }
            },
            [scrollPrev, scrollNext],
        );

        useEffect(() => {
            if (!api || !setApi) {
                return;
            }

            setApi(api);
        }, [api, setApi]);

        useEffect(() => {
            if (!api) {
                return;
            }

            onSelect(api);
            api.on("reInit", onSelect);
            api.on("select", onSelect);

            return () => {
                api.off("select", onSelect);
            };
        }, [api, onSelect]);

        return (
            <CarouselContext.Provider
                value={{
                    carouselRef,
                    api: api,
                    opts,
                    orientation: orientation,
                    scrollPrev,
                    scrollNext,
                    canScrollPrev,
                    canScrollNext,
                }}
            >
                <div
                    ref={ref}
                    onKeyDownCapture={handleKeyDown}
                    className={cx("relative flex", className)}
                    role="region"
                    aria-roledescription="carousel"
                    {...props}
                >
                    {children}
                </div>
            </CarouselContext.Provider>
        );
    },
);
Carousel.displayName = "Carousel";

const CarouselContent = forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
    const { carouselRef, orientation, opts } = useCarousel();
    let alignment =
        orientation === "horizontal"
            ? "-ml-1.5 mr-0.5 lg:-ml-4"
            : "-mt-1.5 mb-0.5 flex-col lg:-mt-4";

    if (opts?.align == "start" && orientation === "horizontal") {
        alignment = "-mr-1.5 ml-0.5 lg:-mr-4";
    } else if (opts?.align == "start" && orientation === "vertical") {
        alignment = "-mb-1.5 mt-0.5 flex-col lg:-mb-4";
    }
    return (
        <div ref={carouselRef} className="overflow-hidden">
            <div
                ref={ref}
                className={cx("flex", alignment, className)}
                {...props}
            />
        </div>
    );
});
CarouselContent.displayName = "CarouselContent";

const CarouselItem = forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
    const { orientation, opts } = useCarousel();
    let alignment =
        orientation === "horizontal" ? "pl-2 lg:pl-4" : "pt-2 lg:pt-4";
    if (opts?.align == "start" && orientation === "horizontal") {
        alignment = "pr-2 lg:pr-4";
    } else if (opts?.align == "start" && orientation === "vertical") {
        alignment = "pb-2 lg:pb-4";
    }
    return (
        <div
            ref={ref}
            role="group"
            aria-roledescription="slide"
            className={cx("min-w-0 shrink-0 grow-0", alignment, className)}
            {...props}
        />
    );
});
CarouselItem.displayName = "CarouselItem";

const CarouselPrevious = forwardRef<
    HTMLButtonElement,
    React.ComponentProps<typeof ChevronButton>
>(
    (
        {
            className,
            variant = "circled",
            size = "sm",
            icon = "chevron",
            iconColor = "black",
            direction = "left",
            ...props
        },
        ref,
    ) => {
        const { orientation, scrollPrev, canScrollPrev } = useCarousel();

        return (
            <>
                {canScrollPrev && (
                    <div className="blur-edges absolute left-0 z-[1] h-full w-7 rotate-180" />
                )}
                <ChevronButton
                    ref={ref}
                    variant={variant}
                    size={size}
                    icon={icon}
                    iconColor={iconColor}
                    direction={direction}
                    className={cx(
                        "absolute disabled:opacity-30",
                        orientation === "horizontal"
                            ? "-left-10 top-1/2 -translate-y-1/2 lg:-left-12"
                            : "-top-10 left-1/2 -translate-x-1/2 rotate-90 lg:-top-12",
                        className,
                    )}
                    disabled={!canScrollPrev}
                    onClick={scrollPrev}
                    {...props}
                >
                    <span className="sr-only">Previous slide</span>
                </ChevronButton>
            </>
        );
    },
);
CarouselPrevious.displayName = "CarouselPrevious";

const CarouselNext = forwardRef<
    HTMLButtonElement,
    React.ComponentProps<typeof ChevronButton>
>(
    (
        {
            className,
            variant = "circled",
            size = "sm",
            icon = "chevron",
            iconColor = "black",
            direction = "right",
            ...props
        },
        ref,
    ) => {
        const { orientation, scrollNext, canScrollNext } = useCarousel();

        return (
            <>
                <ChevronButton
                    ref={ref}
                    variant={variant}
                    size={size}
                    icon={icon}
                    iconColor={iconColor}
                    direction={direction}
                    className={cx(
                        "absolute disabled:opacity-30",
                        orientation === "horizontal"
                            ? "-right-10 top-1/2 -translate-y-1/2 lg:-right-12"
                            : "-bottom-10 left-1/2 -translate-x-1/2 rotate-90 lg:-bottom-12",
                        className,
                    )}
                    disabled={!canScrollNext}
                    onClick={scrollNext}
                    {...props}
                >
                    <span className="sr-only">Next slide</span>
                </ChevronButton>
                {canScrollNext && (
                    <div className="blur-edges absolute right-0 h-full w-7" />
                )}
            </>
        );
    },
);
CarouselNext.displayName = "CarouselNext";

interface CarouselDotsProp {
    position?: "right" | "center" | "left";
}
type EmblaCarouselType = Exclude<CarouselApi, undefined>;
const CarouselDots = forwardRef<HTMLButtonElement, CarouselDotsProp>(
    ({ position = "left", ...props }, ref) => {
        const [idx, setIdx] = useState(0);
        const { api } = useCarousel();
        useEffect(() => {
            const setFn = (a: EmblaCarouselType): void => {
                setIdx(a.selectedScrollSnap());
            };
            api?.on("select", setFn);
            return () => {
                api?.off("select", setFn);
            };
        }, [api]);
        const scrollTo = useCallback(
            (i: number) => {
                api?.scrollTo(i);
            },
            [api],
        );
        return (
            <nav
                ref={ref}
                data-length={api?.scrollSnapList().length ?? 0}
                className={cx(
                    "relative flex items-center gap-1 data-[length='0']:hidden data-[length='1']:hidden",
                    position == "center" && "mx-auto",
                    position == "right" && "right-0 ml-auto",
                    position == "left" && "left-0 mr-auto",
                )}
            >
                {api
                    ?.scrollSnapList()
                    .map((_, i) => (
                        <button
                            key={i}
                            role="navigation"
                            className={cx(
                                "h-2 w-8 cursor-pointer rounded-full border-none p-0 outline-none lg:h-4 lg:w-4",
                                i === idx ? "bg-primary" : "bg-blue-grey-50",
                            )}
                            onClick={() => scrollTo(i)}
                            {...props}
                        />
                    ))}
            </nav>
        );
    },
);
CarouselDots.displayName = "CarouselDots";

export {
    type CarouselApi,
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselPrevious,
    CarouselNext,
    CarouselDots,
};
