import { forwardRef, ReactNode } from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cx } from "class-variance-authority";
import { CheveronLeftUnfilled } from "components/Icons/Chevron";

const chevronButtonVariants = cva(
    "flex cursor-pointer items-center justify-center rounded-full border-none p-0 outline-none",
    {
        variants: {
            variant: {
                default: "bg-transparent",
                circled: "bg-blue-grey-50",
                circled70: "bg-blue-grey-50/70",
            },
            size: { sm: "size-6", md: "size-8" },
        },
        defaultVariants: { variant: "circled", size: "sm" },
    },
);
const chevronIconVariants = cva("", {
    variants: {
        size: { sm: "size-3", md: "size-5" },
        iconColor: {
            black: "text-blue-grey-900",
            blue: "text-blue-grey-600",
        },
        direction: {
            left: "",
            right: "rotate-180",
            up: "rotate-90",
            down: "-rotate-90",
        },
    },
    defaultVariants: { size: "sm", iconColor: "black", direction: "left" },
});

export interface ChevronButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof chevronButtonVariants>,
        VariantProps<typeof chevronIconVariants> {
    icon?: "arrow" | "chevron" | "plus" | "minus";
}

const ChevronButton = forwardRef<HTMLButtonElement, ChevronButtonProps>(
    (
        { className, variant, size, icon, iconColor, direction, ...props },
        ref,
    ) => {
        const iconClassName = chevronIconVariants({
            size,
            iconColor,
            direction,
        });
        let Icon = <CheveronLeftUnfilled className={iconClassName} />;
        if (icon == "arrow") {
            Icon = <ArrowLeftIcon wrapperClassName={iconClassName} />;
        } else if (icon == "plus") {
            Icon = <PlusIcon wrapperClassName={iconClassName} />;
        } else if (icon == "minus") {
            Icon = <MinusIcon wrapperClassName={iconClassName} />;
        }

        return (
            <button
                className={chevronButtonVariants({ variant, size, className })}
                ref={ref}
                {...props}
            >
                {Icon}
            </button>
        );
    },
);
ChevronButton.displayName = "ChevronButton";

export { ChevronButton, chevronButtonVariants };

type IconProps = { className?: string; wrapperClassName?: string };

const ArrowLeftIcon = (props: IconProps): JSX.Element => (
    <IconWrapper className={props.wrapperClassName}>
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
        >
            <path
                fillRule="evenodd"
                d="M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z"
                clipRule="evenodd"
            />
        </svg>
    </IconWrapper>
);

const PlusIcon = (props: IconProps): JSX.Element => (
    <IconWrapper className={props.wrapperClassName}>
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
        >
            <path
                fillRule="evenodd"
                d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                clipRule="evenodd"
            />
        </svg>
    </IconWrapper>
);

const MinusIcon = (props: IconProps): JSX.Element => (
    <IconWrapper className={props.wrapperClassName}>
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
        >
            <path
                fillRule="evenodd"
                d="M4.25 12a.75.75 0 0 1 .75-.75h14a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1-.75-.75Z"
                clipRule="evenodd"
            />
        </svg>
    </IconWrapper>
);

type IconWrapperProps = { children: ReactNode; className?: string };
const IconWrapper = (props: IconWrapperProps): JSX.Element => (
    <div className={cx("flex items-center p-0", props.className)}>
        {props.children}
    </div>
);
